import React, { ReactNode } from "react";
import ctl from "@netlify/classnames-template-literals";

type TextAlign = "left" | "center" | "right" | "none";
export type TextColor =
  | "primary"
  | "primary-light"
  | "secondary"
  | "secondary-light"
  | "tertiary"
  | "tertiary-dark"
  | "accent-dark"
  | "accent-primary"
  | "accent-light"
  | "accent-lighter"
  | "light"
  | "white"
  | "error"
  | "warning"
  | "valid"
  | "valid-dark"
  | "success"
  | "success-light"
  | "success-dark";

type TextComponent =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "li"
  | "span";
type TextFontFamily = "sans" | "centra" | "recife";
type TextGutter = "none" | "xs" | "sm" | "md" | "lg" | "xl";
type TextLineClamp = 1 | 2 | 3;
export type TextSize =
  | "5xl"
  | "4xl"
  | "3xl"
  | "2xl"
  | "xl"
  | "base"
  | "sm"
  | "xs";
type TextVariant =
  | "headline"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "body"
  | "li"
  | "label"
  | "link";
export type TextWeight = "bold" | "medium" | "regular";

interface Props {
  align?: TextAlign;
  alignVertical?: boolean;
  children?: ReactNode;
  color?: TextColor | undefined;
  component?: TextComponent;
  fontFamily?: TextFontFamily;
  gutter?: TextGutter;
  lineClamp?: TextLineClamp;
  ref?: React.Ref<HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement>;
  size?: TextSize;
  variant?: TextVariant;
  weight?: TextWeight;
  wrap?: boolean;
}

export default function Text({
  align = "left",
  alignVertical = false,
  children,
  color,
  component,
  fontFamily = "sans",
  gutter = "none",
  lineClamp,
  size = "base",
  variant = "body",
  weight = "regular",
  wrap = false,
}: Readonly<Props>): JSX.Element {
  const className = getClasses({
    align,
    alignVertical,
    color,
    fontFamily,
    gutter,
    lineClamp,
    size,
    variant,
    weight,
    wrap,
  });
  const Component = getComponent(variant, component);

  return <Component className={className}>{children}</Component>;
}

export function getClasses({
  align,
  alignVertical,
  color,
  fontFamily,
  gutter,
  lineClamp,
  size,
  variant,
  weight,
  wrap,
}: {
  align: TextAlign;
  alignVertical?: boolean;
  color?: TextColor;
  fontFamily: TextFontFamily;
  gutter: TextGutter;
  lineClamp?: TextLineClamp;
  size: TextSize;
  variant: TextVariant;
  weight: TextWeight;
  wrap: boolean;
}): string {
  const alignClass = {
    left: "text-left",
    center: "text-center",
    right: "text-right",
    none: "",
  };

  const alignVerticalClass = alignVertical ? "flex items-center gap-2" : "";

  const bodyFontClass = {
    centra: "font-centra",
    sans: "font-sans",
    recife: "font-recife",
  };

  const bodySizeClass = {
    "5xl": "lg:text-5xl text-3xl",
    "4xl": "text-3xl lg:text-4xl",
    "3xl": "text-2xl lg:text-3xl",
    "2xl": "lg:text-2xl text-xl",
    xl: "lg:text-xl text-base",
    base: "text-base",
    sm: "text-sm",
    xs: "text-xs",
  };

  const gutterClass = {
    none: "mb-0",
    xs: "mb-1",
    sm: "mb-2",
    md: "mb-4",
    lg: "mb-6",
    xl: "mb-8",
  };

  const lineClampClass = {
    1: "line-clamp-1",
    2: "line-clamp-2",
    3: "line-clamp-3",
  };

  const bodyWeightClass = {
    bold: "font-bold",
    medium: "font-medium",
    regular: "font-normal",
  };

  const colorClass = getColorClass(color);

  const wrapClass = wrap
    ? "whitespace-normal break-words"
    : "whitespace-pre-line";

  const textClasses = {
    headline: "lg:text-6xl font-bold font-recife leading-tight text-3xl",
    h1: "text-4xl font-bold font-centra",
    h2: "text-3xl font-bold font-centra",
    h3: "text-2xl font-bold font-centra",
    h4: "font-base font-centra",
    body: `font-base ${wrapClass} ${bodySizeClass[size]} ${bodyWeightClass[weight]} ${bodyFontClass[fontFamily]}`,
    li: "font-base",
    label: "text-xs font-bold font-centra tracking-wide",
    link: "underline cursor-pointer",
  };

  return ctl(`
    ${alignClass[align]}
    ${alignVerticalClass}
    ${gutterClass[gutter]}
    ${textClasses[variant]}
    ${colorClass}
    ${lineClamp ? lineClampClass[lineClamp] : ""}`);
}

function getColorClass(color?: TextColor): string {
  if (!color) {
    return "";
  }

  switch (color) {
    case "primary":
      return "text-neutral-900";
    case "primary-light":
      return "text-neutral-700";
    case "accent-primary":
      return "text-purple-500";
    case "secondary":
      return "text-neutral-600";
    case "secondary-light":
      return "text-neutral-500";
    case "tertiary":
      return "text-purple-800";
    case "tertiary-dark":
      return "text-purple-900";
    case "light":
      return "text-neutral-200";
    case "accent-lighter":
      return "text-purple-200";
    case "accent-light":
      return "text-purple-300";
    case "accent-dark":
      return "text-purple-600";
    case "white":
      return "text-white";
    case "success":
      return "text-status-green-500";
    case "success-dark":
      return "text-status-green-600";
    case "success-light":
      return "text-green-200";
    case "error":
      return "text-status-red-500";
    case "warning":
      return "text-status-yellow-500";
    case "valid":
      return "text-status-teal-500";
    case "valid-dark":
      return "text-status-teal-600";
    default:
      return "text-neutral-900";
  }
}

function getComponent(variant: TextVariant, component?: TextComponent) {
  if (component) {
    return component;
  }

  switch (variant) {
    case "h1":
    case "headline":
      return "h1";
    case "h2":
      return "h2";
    case "h3":
      return "h3";
    case "h4":
      return "h4";
    case "body":
      return "p";
    case "li":
      return "li";
    case "label":
    default:
      return "span";
  }
}
