import ctl from "@netlify/classnames-template-literals";

export const dialog = ctl(`
  fixed
  inset-0
  z-100
  overflow-y-auto
`);

export const overlay = ctl(`
  fixed
  inset-0
  bg-black
  bg-opacity-40
`);

export const contentWrapper = ctl(`
  flex
  h-full
  w-full
  items-center
  justify-center
  p-4
`);

export const content = ctl(`
  inline-block
  w-full
  transform
  overflow-hidden
  rounded-xl
  bg-white
  p-8
  shadow-lg
  transition-all
`);

export const contentCenteredInner = ctl(`
  grid
  grid-flow-row
  auto-rows-max
  gap-4
`);

export const buttonWrapper = ctl(`
  absolute
  top-1
  right-1
  md:top-4
  md:right-4
`);

export const iconWrapper = ctl(`
  flex
  justify-center
`);
